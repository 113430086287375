import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import axios from 'axios';
import QRCode from "react-qr-code";
import { LinearProgress } from '@mui/material';
const { Buffer } = require('buffer');
const host = process.env.REACT_APP_APIHOST || 'https://knmi.ruijs.fr'

const BarcodeGenerator = () => {
    const [name, setName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [blobURL, setBlobURL] = useState(null);
    const [qrURL, setQRURL] = useState(null);
    const [error, setError] = useState(null);
    const [show, setShow] = useState(null)

    const handleSubmit = async (e) => {
        setShow(true)
        e.preventDefault();
        try {
            const response = await axios.post(
                `${host}/streepjescode`,
                {
                    name: name,
                    birthday: birthday
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                    },

                    //responseType: 'arraybuffer' // to handle binary response
                }
            );
            //const blob = new Blob([response.data], { type: 'image/png' });


            //const byteCharacters = atob(response.data.blob);
            console.log(response.data.blob)
            const binaryData = Buffer.from(response.data.blob, 'base64');
            console.log(binaryData)
            const blob = new Blob([binaryData], { type: 'image/png' });
            const imageBlobUrl = URL.createObjectURL(blob);


            if (response.data.filename != null) {
                setQRURL(`${host}/streepjescode/${response.data.filename}`)
            }
            setBlobURL(imageBlobUrl);
            setError(null);
        } catch (error) {
            setError(error.message);
            setBlobURL(null);
        }
        setShow(false)
    };

    return (
        <div style={{ 'padding': '1px' }}>

            <div style={{ float: 'right', paddingRight: '10px' }}>
                {
                    qrURL && <QRCode value={qrURL} />

                }
                {show && <Box id="progress" sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
                }
            </div>
            <div>
                <form onSubmit={handleSubmit}>
                    <ul style={{ listStyleType: 'none' }}>
                        <li style={{ 'paddingBottom': '10px' }}>
                            <label>
                                Naam:&nbsp;
                                <input
                                    pattern="[a-zA-Z-]+"
                                    type="text"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        setBlobURL(null)
                                        setQRURL(null)
                                    }} />
                            </label>
                        </li>
                        <li style={{ 'paddingBottom': '10px' }}>
                            <label>
                                Geboortedatum:&nbsp;
                                <input type="date"
                                    min="1901-01-01"
                                    max="2024-03-13"
                                    value={birthday}
                                    onChange={(e) => {
                                        setBirthday(e.target.value);
                                        setBlobURL(null)
                                        setQRURL(null)
                                    }} />
                            </label>
                        </li>
                        {
                            !blobURL && <li style={{ 'paddingBottom': '10px' }}>
                                <button
                                    className="btn"
                                    type="submit">Bereken jouw klimaatstreepjes</button>
                            </li>}

                        {
                            blobURL && <li style={{ 'paddingBottom': '10px' }}>
                                <a
                                    target="_blank"
                                    className="btn"
                                    href={blobURL}
                                >download</a></li>
                        }
                    </ul>

                </form>
            </div>
            <div style={{ textAlign: 'center' }}>
                {error && <p>Error: {error}</p>}
                {blobURL && <img style={{ width: '100%' }} src={blobURL} alt="image" />}
            </div>
        </div >
    );
};

export default BarcodeGenerator;
